import { BrowserComponent } from './components/browser/browser.component';
import { ActionComponent } from './components/action/action.component';
import { TriggerComponent } from './components/trigger/trigger.component';
import { RuleComponent } from './components/rule/rule.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, enableProdMode } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenubarModule } from 'primeng/menubar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MenuModule } from 'primeng/menu';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { MultiSelectModule } from 'primeng/multiselect';
import { TagModule } from 'primeng/tag';
import { PickListModule } from 'primeng/picklist';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';

enableProdMode();

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

import { detect } from 'detect-browser';
import { HttpConfigInterceptor } from './Interceptors/httpconfig.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
const keycloakService = new KeycloakService();
const browser = detect();

@NgModule({
  declarations: [
    AppComponent,
    RuleComponent,
    TriggerComponent,
    ActionComponent,
    BrowserComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,

    AccordionModule,
    MenubarModule,
    InputTextModule,
    ButtonModule,
    CardModule,
    TabViewModule,
    DialogModule,
    FormsModule,
    DropdownModule,
    InputTextareaModule,
    TableModule,
    InputNumberModule,
    ConfirmDialogModule,
    ProgressSpinnerModule,
    ToastModule,
    ChartModule,
    CalendarModule,
    ScrollPanelModule,
    RadioButtonModule,
    MenuModule,
    ChipModule,
    ChipsModule,
    MultiSelectModule,
    TagModule,
    PickListModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    CookieService,
    MessageService,
    ConfirmationService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: KeycloakService,
      useValue: keycloakService
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
  ],
  // bootstrap: [AppComponent]
  entryComponents: [ AppComponent ]
})
export class AppModule {
  constructor(
    ) {
    localStorage.setItem('oi', '');
    localStorage.setItem('ui', '');
  }

  ngDoBootstrap(app) {
    localStorage.setItem('browser', 'true');
    keycloakService
      .init(
        {
          config: {
            url: environment.keycloakUrl + '/auth',
            realm: environment.realm,
            clientId: environment.clientid
          },
          initOptions: {
            onLoad: 'login-required',
            checkLoginIframe: false
          },
          enableBearerInterceptor: true,
          bearerPrefix: 'Bearer',
          bearerExcludedUrls: [
              '/assets',
              '/clients/public']
        })
      .then(async () => {
        // console.log('[ngDoBootstrap] bootstrap app');

        // console.log((await keycloakService.loadUserProfile()).email)

        localStorage.setItem('oi', '');
        localStorage.setItem('ui', '');
        app.bootstrap(AppComponent);

        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production });
      })
      .catch(
        error => console.error('[ngDoBootstrap] init Keycloak failed', error)
      );
  }
 }
